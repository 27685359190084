exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-bueroreinigung-tsx": () => import("./../../../src/pages/bueroreinigung.tsx" /* webpackChunkName: "component---src-pages-bueroreinigung-tsx" */),
  "component---src-pages-dsgvo-tsx": () => import("./../../../src/pages/dsgvo.tsx" /* webpackChunkName: "component---src-pages-dsgvo-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-fensterreinigung-tsx": () => import("./../../../src/pages/fensterreinigung.tsx" /* webpackChunkName: "component---src-pages-fensterreinigung-tsx" */),
  "component---src-pages-grundreinigung-tsx": () => import("./../../../src/pages/grundreinigung.tsx" /* webpackChunkName: "component---src-pages-grundreinigung-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kampagnen-kampangne-1-tsx": () => import("./../../../src/pages/kampagnen/kampangne1.tsx" /* webpackChunkName: "component---src-pages-kampagnen-kampangne-1-tsx" */),
  "component---src-pages-kampagnen-kampangne-2-tsx": () => import("./../../../src/pages/kampagnen/kampangne2.tsx" /* webpackChunkName: "component---src-pages-kampagnen-kampangne-2-tsx" */),
  "component---src-pages-kampagnen-kampangne-3-tsx": () => import("./../../../src/pages/kampagnen/kampangne3.tsx" /* webpackChunkName: "component---src-pages-kampagnen-kampangne-3-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-treppenhausreinigung-tsx": () => import("./../../../src/pages/treppenhausreinigung.tsx" /* webpackChunkName: "component---src-pages-treppenhausreinigung-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

